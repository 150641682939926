import React from 'react';
import Header from './header';// Import your existing header

const IDXHeaderWrapper = () => { 

    return (   
        <div id="idx-header-wrapper">     
            <Header />
        </div>
    );
};

export default IDXHeaderWrapper; 