// resources/agents.js

const agents = [
    "Christina Cavallo REB.0795023",
    "David Buccitti RES.0762785",
    "Isabelle Cavallo RES.0825718",
    "Ken Freundt RES.0815090",
    "Brett Jackson RES.0820251",
    "Tammi Ligouri RES.0773592",
    "Maccarthy Jean RES.0785301",
    "Chris Robinson RES.0765310"
  ];
  
  export default agents;
  